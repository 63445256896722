@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100vh;
  background-color: #212121;
  color: whitesmoke;
  transition: all 0.5s linear;
}

.lightmode {
  height: 100vh;
  background-color: whitesmoke;
  color: #212121;
  transition: all 0.5s linear;
}

.toggle {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 10;
}

@media (max-width: 400px) {
  .toggle .text {
    display: none;
  }
}

.title {
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Nunito", sans-serif;
}

.header {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 30%;
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

@media (min-width: 900px) {
  .title {
    font-size: 7rem;
  }
  .inputs {
    flex-direction: row;
  }
}

.meanings {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 55vh;
  border: 10px solid rgb(105, 105, 105);
  border-radius: 10px;
  padding: 10px 20px;
  overflow-x: hidden;
}

.subTitle {
  font-size: 5vw;
  font-family: "Nunito", sans-serif;
}

@media (max-width: 900px) {
  .meanings {
    overflow: scroll;
    overflow-x: hidden;
    height: 60vh;
  }
}
.singleMean {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0;
}

.footer {
  font-size: 1rem;
  text-align: center;
  padding: auto 1em;
  font-family: "Nunito", sans-serif;
}
a {
  color: rgb(103, 24, 194);
  transition: all 0.5s ease-in-out;
}
a:hover {
  color: rgb(192, 139, 253);
}

