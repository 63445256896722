.meanings {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 55vh;
  border: 10px solid rgb(105, 105, 105);
  border-radius: 10px;
  padding: 10px 20px;
  overflow-x: hidden;
}

.subTitle {
  font-size: 5vw;
  font-family: "Nunito", sans-serif;
}

@media (max-width: 900px) {
  .meanings {
    overflow: scroll;
    overflow-x: hidden;
    height: 60vh;
  }
}
.singleMean {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0;
}
