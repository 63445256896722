.title {
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Nunito", sans-serif;
}

.header {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 30%;
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

@media (min-width: 900px) {
  .title {
    font-size: 7rem;
  }
  .inputs {
    flex-direction: row;
  }
}
